@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1550px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1450px) {
        @content;
    }
}

@mixin extra-small-breakpoint {
    @media screen and (max-width: 900px) {
        @content;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    height: fit-content;
    gap: 182px;
    overflow: hidden;

    @include media-tablet {
        flex-direction: column;
        gap: 90px;
        overflow: hidden;
    }

    @include media-mobile {
        gap: 10px;
    }
}

.description {
    display: flex;
    gap: 40px;
    flex-direction: column;
    z-index: 1;

    @include small-breakpoint {
        gap: 35px;
    }

    @include media-tablet {
        flex-direction: row;
        justify-content: space-between;
    }
}

.apply {
    display: grid;
    grid-template-columns: repeat(2, 250px);
    gap: 20px;
    height: fit-content;
    position: relative;
    z-index: 100;

    @include media-tablet {
        grid-template-columns: 250px;
    }

    @include extra-small-breakpoint {
        display: none;
    }
}

.mainHeading {
    font-size: 72px;
    font-weight: 500;
    line-height: 79px;
    white-space: nowrap;

    @include medium-breakpoint {
        font-size: 54px;
        line-height: 54px;
    }

    @include media-tablet-portrait {
        font-size: 42px;
        font-weight: 500;
    }

    @include media-mobile {
        font-size: 28px;
        font-weight: 500;
        line-height: 31px;
    }
}

.info {
    font-size: 28px;
    font-weight: 400;
    line-height: 39px;
    
    display: flex;
    flex-direction: column;
    gap: 22px;

    @include small-breakpoint {
        font-size: 22px;
        line-height: 28px;
        gap: 15px;
    }

    @include media-mobile {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }
}

.dates {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    color: #A2A8AD;

    @include small-breakpoint {
        font-size: 18px;
        line-height: 28px;
    }

    @include media-mobile {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }
}

.imagesContainer {
    z-index: 0;
    @include media-tablet {
        height: 300px;
    }

    @include media-tablet-portrait {
        height: 200px;
    }
}

.mainSanta {
    position: absolute;
    width: 500px;
    aspect-ratio: 1 / 1;
    right: 67px;
    bottom: -30px;

    @include medium-breakpoint {
        width: 400px;
        top: auto;
        bottom: -30px
    }

    @include small-breakpoint {
        right: 12px;
    }

    @include media-tablet {
        width: 50%;
        left: 50%;
        transform: translate(-50%);
        right: auto;
    }

    @include media-tablet-portrait {
        width: 55%;
    }

    @include media-mobile {
        width: 60%;
    }

    @include media-mobile-xs {
        width: 90%;
    }
}

.lights {
    pointer-events: none;
    position: absolute;
    width: 850px;
    aspect-ratio: 1 / 1;
    right: 0;
    top: -140px;
    pointer-events: none;
    z-index: 0;

    @include medium-breakpoint {
        width: 680px;
        top: -50px;
    }

    @include small-breakpoint {
        width: 600px;
        top: -70px;
        right: -9px;
    }

    @include media-tablet {
        display: none;
    }
}

.lightsHorizontal {
    pointer-events: none;
    display: none;
    position: absolute;
    width: 100%;
    top: 35%;
    left: 0;
    aspect-ratio: 1 / 1;
    z-index: 0;

    @include media-tablet {
        display: block;
    }

    @include media-tablet-portrait {
        top: 50%;
    }
}

.buttonContainer {
    margin: 0;
    padding: 0;
    border: none;
}
